<template>
  <!-- <ion-header mode="md" class="ion-no-border">
    <ion-toolbar>
      <ion-buttons>
        <ion-button>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 12H5" stroke="#545454" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 19L5 12L12 5" stroke="#545454" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </ion-button>
        <ion-title>Kuis</ion-title>
      </ion-buttons>
    </ion-toolbar>
  </ion-header> -->
  <ion-content class="ion-padding" :fullscreen="true">
    <div class="container relative">
      <ion-progress-bar class="progress" :value="progress" color="dark" mode="md"></ion-progress-bar>
      <a class="ads0c mt-2" :href="ads.link" target="_blank">
        <video width="320" height="240" autoplay v-if="ads.type=='video'" ref="video_player">
          <source :src="ads.src" type="video/mp4">
        </video>
        <img :src="ads.src" v-else />
      </a>
    </div>
  </ion-content>
  <ion-footer class="ion-no-border">
    <ion-toolbar>
      <ion-title class="text-center" v-if="skip">
        <a href="#" class="skip-link" @click.prevent="close()">Skip Iklan ></a>
      </ion-title>
    </ion-toolbar>
  </ion-footer>
</template>

<script>
import { IonContent, modalController, IonFooter, IonToolbar, IonTitle, IonProgressBar } from '@ionic/vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Modal',
  props: {
    ads: Object
  },
  data() {
    return {
      progress: 0,
      duration: 0,
      skip: false
    }
  },
  components: {
    IonContent,
    IonToolbar,
    IonTitle,
    IonProgressBar,
    IonFooter
  },
  methods: {
    async close() {
      if(window !== undefined){
        window.open(this.ads.link, '_blank')
      }
      await modalController.dismiss()
    },
    progressLoad() {
      let counter = 0;
      let timer = setInterval(() => {
        counter += 100;
        this.progress = (this.duration + (counter - this.duration)) / this.duration
        if(counter >= this.duration ){
          clearInterval(timer)
          this.skip = true;
        }
      }, 100)
    },
  },
  mounted () {
    this.duration = (this.ads.skip_time * 1000)
    this.progressLoad()
  }
});
</script>

<style langs="scss">
.skip-link {
  font-size: 14px;
  text-decoration: none;
  color: #545454;
  cursor: pointer;
}
.toolbar-content {
  text-align: center !important;
}
.progress {
  border-radius: 2px;
}

.ads0c {
  display: flex;
  justify-content: center;
  text-decoration: none;
}
</style>
