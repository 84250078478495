<template>
  <ion-page>
    <HeaderPage title="Kuis" />
    <ion-content :fullscreen="true">
      <section class="container quiz" v-if="questions">
        <div class="page">{{ index+1 }}/{{ questions.length }}</div>
        <div class="question" >
          {{ questions[index].question }}
        </div>
        <div class="options">
          <div class="option" v-for="(option, i) in questions[index].options" :key="i" :class="{'checked': checked == i}" @click="select(i)">
            <input type="radio" name="option" :value="i">
            <label class="w100">{{ option }}</label>
          </div>
        </div>
      </section>
    </ion-content>
    <ion-footer class="ion-no-border container">
      <ion-toolbar>
        <ion-button mode="ios" shape="round" expand="block" color="dark" class="btn mt-10" @click="next" v-if="!finish" :disabled="disable">Selanjutnya</ion-button>
        <ion-button mode="ios" shape="round" expand="block" color="dark" class="btn mt-10" @click="submit" v-if="finish" :disabled="disable">Selesai</ion-button>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonContent, IonPage, IonButton, IonFooter, IonToolbar, modalController
} from '@ionic/vue';
import HeaderPage from '@/components/headerPage'
import Modal from '@/components/modal'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    HeaderPage,
    IonButton,
    IonFooter,
    IonToolbar
  },
  data () {
    return {
      index: 0,
      checked: null,
      finish: false,
      question: null,
      disable: false
    }
  },
  mounted () {
    this.start()
  },
  watch: {
    '$route' (from, to)  {
      if(to.name === 'Quiz') {
        this.start()
      }
    }
  },
  methods: {
    next() {
      if(this.checked == null) {
        alert('silahkan pilih jawaban dahulu')
        return;
      }

      if((this.questions.length - 1) > this.index){
        this.index = this.index+1
        if(this.index == 1) {
          this.openModal()
        }
        this.checked = null
      }

      if((this.questions.length - 1) == this.index) {
        this.finish = true
      }
    },
    select(i) {
      let question = this.questions[this.index]
      this.checked = i
      this.$store.commit('quiz/setAnswer', {
        question_id: question.id,
        answer: this.checked
      })
    },
    async openModal() {
      const modal = await modalController
        .create({
          component: Modal,
          cssClass: 'ads-css',
          backdropDismiss: false,
          keyboardClose: false,
          componentProps: {
            ads: this.$ads
          }
        })
      return modal.present();
    },
    submit() {
      this.$store.dispatch('quiz/submitQuiz').then(res => {
        this.index = 0
        this.checked = null
        this.finish = false
        this.question = null
        this.disable = false
        this.$router.push({name: 'QuizResult', params: { id: res.data.id }})
      })
    },
    async start () {
      let res = await this.$store.dispatch('quiz/quizStart').then(res => res)
      if(res.error){
        this.disable = true
        // this.$router.push('/')
      }
      await this.$store.dispatch('quiz/fetchQuestion')
      await this.$store.dispatch('ad5/fetchAds')
    }
  },
  computed: {
    ...mapGetters({
      questions: 'quiz/getQuestion',
      $auth: 'auth/getLoggedIn',
      $ads: 'ad5/getAdsLoad'
    })
  }
})
</script>

<style lang="scss">
.quiz {
  .page {
    text-align: center;
    font-size: 14px;
  }
  .question {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2rem;
  }
  .option {
    height: 80px;
    text-align: center;
    display: flex;
    align-items: center;
    border: 1px solid #DDDDDD;
    margin-bottom: 1rem;
    border-radius: 5px;
    padding: 1rem;
    &.checked {
      background: #000;
      color: #fff;
    }
    label {
      width: 100%;
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      height: 21px;
      margin: 0;
    }
  }
}
</style>
